import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';

import { getPassesById } from 'state/selectors';
import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

import { ALL_CARDS_ROUTE } from 'constants/navigation';

const LazySuspendedLoyaltyCardPage = loadable(() => import('suspended-page/loyalty-card'));

const LoyaltyCardViewPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const [passState, setPassState] = useState('');
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  const { navigate = () => {}, homePageOnly = () => {} } = useNavigationContext();
  const authenticated = isAuthenticated();

  useEffect(() => {
    const onFindPass = async () => {
      const { state = '', name = '' } = await getPassesById(passId);
      setTitle(name);
      if (state) {
        setPassState(state);
        setLoading(false);
      } else {
        navigate(`${ALL_CARDS_ROUTE}/?bounced=true`);
      }
    };

    onFindPass();

    // eslint-disable-next-line
  }, []);

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  return (
    <LazySuspendedLoyaltyCardPage
        passId={passId}
        title={title}
        passState={passState}
        loading={loading}/>
  );
};

LoyaltyCardViewPage.propTypes = {
  location: object,
};

export default LoyaltyCardViewPage;
